var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.bannerSearchArray.length && !_vm.isRoleF3 ? _c('div', {
    ref: "bannerContainerRef",
    staticClass: "position-relative",
    on: {
      "mouseenter": _vm.pauseSwiper,
      "mouseleave": _vm.resumeSwiper
    }
  }, [_c('swiper', {
    ref: "searchResultBannerRef",
    staticClass: "swiper swiper-navigation rounded-8px my-50",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.bannerSearchArray, function (slide) {
    return _c('swiper-slide', {
      key: slide,
      staticClass: "slide d-flex-center"
    }, [_c('BImg', {
      staticClass: "rounded-8px",
      style: "height: ".concat(_vm.heightBanner, "px; object-fit: fill; max-height: 400px"),
      attrs: {
        "fluid-grow": "",
        "src": slide
      }
    })], 1);
  }), _c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }), _vm.bannerSearchArray.length > 1 ? _c('div', {
    class: "swiper-button-prev ".concat(_vm.isHover ? 'hover' : ''),
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }, [_c('b-button', {
    staticClass: "rounded-circle border d-flex-center p-25 p-md-75 ml-1 ml-md-3",
    attrs: {
      "variant": "white"
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "ChevronLeftIcon",
      "size": "30"
    }
  })], 1)], 1) : _vm._e(), _vm.bannerSearchArray.length > 1 ? _c('div', {
    class: "swiper-button-next ".concat(_vm.isHover ? 'hover' : ''),
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }, [_c('b-button', {
    staticClass: "rounded-circle border d-flex-center p-25 p-md-75 mr-1 mr-md-3",
    attrs: {
      "variant": "white"
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "ChevronRightIcon",
      "size": "30"
    }
  })], 1)], 1) : _vm._e()], 2), _c('BButton', {
    staticClass: "position-absolute p-50 rounded-circle",
    staticStyle: {
      "right": "5px",
      "top": "5px",
      "z-index": "9"
    },
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.onClickCloseBannerHandle
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "clear",
      "color": "#EF5350",
      "size": "20"
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }