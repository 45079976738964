<template lang="">
  <div
    v-if="bannerSearchArray.length && !isRoleF3"
    ref="bannerContainerRef"
    class="position-relative"
    @mouseenter="pauseSwiper"
    @mouseleave="resumeSwiper"
  >
    <swiper
      ref="searchResultBannerRef"
      class="swiper swiper-navigation rounded-8px my-50"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="slide in bannerSearchArray"
        :key="slide"
        class="slide d-flex-center"
      >
        <BImg
          class="rounded-8px"
          fluid-grow
          :style="`height: ${heightBanner}px; object-fit: fill; max-height: 400px`"
          :src="slide"
        />
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination"
      />

      <!-- ANCHOR Arrows -->
      <div
        v-if="bannerSearchArray.length > 1"
        slot="button-prev"
        :class="`swiper-button-prev ${isHover ? 'hover' : ''}`"
      >
        <b-button
          variant="white"
          class="rounded-circle border d-flex-center p-25 p-md-75 ml-1 ml-md-3"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="text-body"
            size="30"
          />
        </b-button>
      </div>

      <div
        v-if="bannerSearchArray.length > 1"
        slot="button-next"
        :class="`swiper-button-next ${isHover ? 'hover' : ''}`"
      >
        <b-button
          variant="white"
          class="rounded-circle border d-flex-center p-25 p-md-75 mr-1 mr-md-3"
        >
          <feather-icon
            icon="ChevronRightIcon"
            class="text-body"
            size="30"
          />
        </b-button>
      </div>
    </swiper>

    <BButton
      variant="flat-danger"
      class="position-absolute p-50 rounded-circle"
      style="right: 5px; top: 5px; z-index: 9;"
      @click="onClickCloseBannerHandle"
    >
      <IAmIcon
        icon="clear"
        color="#EF5350"
        size="20"
      />
    </BButton>
  </div>
</template>
<script>
import { BButton, BImg } from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import store from '@/store'

export default {
  components: {
    BImg,
    BButton,
    Swiper,
    SwiperSlide,
  },
  setup(_, { emit }) {
    const bannerContainerRef = ref()
    const searchResultBannerRef = ref()
    const heightBanner = computed(() => bannerContainerRef.value ? (bannerContainerRef.value.offsetWidth / 4) : 300)
    const bannerSearchArray = computed(() => store.getters['app/getBannerSearch'] ?? [])
    const swiperOptions = computed(() => ({
      // freeMode: true,
      slidesPerView: 1,
      // gridMode: true,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 30,
      loop: bannerSearchArray.value && bannerSearchArray.value.length > 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className}" style=""></span>`
        },
      },
    }))

    function onClickCloseBannerHandle() {
      emit('closeBanner')
    }

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const isHover = ref(false)
    function pauseSwiper() {
      if (searchResultBannerRef.value) {
        searchResultBannerRef.value.swiperInstance.autoplay.pause()
      }
      isHover.value = true
    }

    function resumeSwiper() {
      if (searchResultBannerRef.value) {
        // searchResultBannerRef.value.swiperInstance.autoplay.run()
      }

      isHover.value = false
    }
    return {
      swiperOptions,
      onClickCloseBannerHandle,
      bannerSearchArray,
      bannerContainerRef,
      searchResultBannerRef,
      heightBanner,
      isRoleF3,
      pauseSwiper,
      resumeSwiper,
      isHover,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

// .slide {
//    flex-shrink: unset;
// }
.swiper-button-prev {
  margin-top: 0;
  translate: -20% -50%;

  opacity: 0;
  &.hover {
    transition: 0.5s;
    opacity: 1;
  }

  &:after {
    background-image: unset !important;
  }
}

.swiper-button-next {
  opacity: 0;
  margin-top: 0;
  translate: 20% -50%;

  &.hover {
    transition: 0.5s;
    opacity: 1;
  }

  &:after {
    background-image: unset !important;
  }
}

.swiper-wrapper ::v-deep {
  transform: none !important;
}

.swiper-pagination ::v-deep {
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
}
</style>
